import { Job } from "@/dto/job/job";
import { useFetchStateStore } from "@/store/fetch-state";
import { useApplicationStore } from "@/store/application";
import { useChatStore } from "@/store/chat";
import type { Candidate } from "@/dto/candidate/candidate";

export const useJobStore = defineStore("job", () => {
  const jobs = ref<Job[]>([]);
  const recommendedCandidates = ref<(Candidate & { similarity: number })[]>([]);

  const shareJobModal = ref<{ active: boolean; job?: Job; showInvite?: boolean; title: string }>({
    active: false,
    job: undefined,
    showInvite: false,
    title: "",
  });
  const fetchStateStore = useFetchStateStore();
  const { applications } = storeToRefs(useApplicationStore());
  const { chats } = storeToRefs(useChatStore());

  const fetching = computed(() => fetchStateStore.fetching["jobs"]);
  const fetchingRecommendedCandidates = computed(() => fetchStateStore.fetching["recommended_candidates"]);

  const getJobs = async () => {
    await fetchStateStore.fetchOnce("jobs", async () => {
      jobs.value = await $api<Job[]>(API_ROUTES.job);
    });
  };

  const getOne = async ({ id, full = true }: { id: number; full?: boolean }) => {
    const job = await $api<Job>(`${API_ROUTES.job}/${id}?full=${full}`, {
      method: "get",
    });

    if (job) addJobToStore(job);
    return job;
  };

  const createJob = async (data: Job) => {
    const job = await $api<Job>(API_ROUTES.job, {
      method: "post",
      body: data,
    });
    if (job) addJobToStore(job);
    return job;
  };

  const updateJob = async (jobData: Partial<Job>, jobId: number, options?: Record<string, any>) => {
    const endpoint = options?.repost ? `${API_ROUTES.jobRepost}/${jobId}` : `${API_ROUTES.job}/${jobId}`;
    const job = await $api<Job>(endpoint, {
      method: "patch",
      body: jobData,
    });
    if (job) addJobToStore(job);
    return { error: null };
  };

  const archiveJob = async ({ id, removeAppsAndChats = false }: { id: number; removeAppsAndChats?: boolean }) => {
    const job = await $api<Job>(`${API_ROUTES.jobArchive}/${id}`, {
      body: { removeAppsAndChats },
      method: "patch",
    });
    if (job) addJobToStore(job);
    return { error: null };
  };

  const deleteJob = async (jobId: number) => {
    const job = await $api<Partial<Job>>(`${API_ROUTES.job}/${jobId}`, {
      method: "delete",
    });
    if (job) removeJobFromStore(job);
    return { error: null };
  };

  const getMatchingCandidates = async (jobId: number) => {
    await fetchStateStore.fetchOnce("recommended_candidates", async () => {
      recommendedCandidates.value = await $api<(Candidate & { similarity: number })[]>(`${API_ROUTES.jobMatchingCandidates}/${jobId}`);
    });
    return recommendedCandidates.value;
  };

  const clearRecommendedCandidates = () => {
    recommendedCandidates.value = [];
    fetchStateStore.clearFetchedState("recommended_candidates"); 
  };

  const getJobIndex = (job: Job | Partial<Job>) => {
    if (!job) throw createError({ statusMessage: ERROR_MESSAGES.job_not_found });
    return jobs.value.findIndex((j: Job) => j.id === job.id);
  };

  const addJobToStore = (job: Job) => {
    const index = getJobIndex(job);
    if (index !== -1) {
      jobs.value[index] = job;
      //TODO: Could do this better
      applications.value.filter((app) => app.job.id === job.id).forEach((app) => (app.job = job));
      chats.value.filter((chat) => chat.job.id === job.id).forEach((chat) => (chat.job = job));
    } else {
      jobs.value.unshift(job);
    }
  };

  const removeJobFromStore = (job: Job | Partial<Job>) => {
    const index = getJobIndex(job);
    index !== -1 ? jobs.value.splice(index, 1) : null;
  };

  return { jobs, fetching, createJob, updateJob, archiveJob, deleteJob, getJobs, getOne, addJobToStore, removeJobFromStore, shareJobModal, getMatchingCandidates, recommendedCandidates, fetchingRecommendedCandidates, clearRecommendedCandidates };
});
